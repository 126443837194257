

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonBadge, IonList, IonListHeader, IonThumbnail  } from '@ionic/vue';

import { ref } from 'vue';

// icons
import { location } from 'ionicons/icons';

// services
import OrderService from '@/services/OrderService';
import { useRoute } from 'vue-router';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  name: 'OrderDetailPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonBadge, IonList, IonListHeader, IonThumbnail },
  setup() {
    const loading = ref(true);
    const loadingOrderItems = ref(false);
    const orderItems = ref<any>(null);
    const order = ref<any>(null);

    // methods
    const { t } = useI18n();
    const { getOrderStatusColor } = utils();
    const route = useRoute();
    const passedOrder: any = history.state.passedOrder; // route paramters (mainly ID)
    if (passedOrder) { // from cart page
      loading.value = false;
      loadingOrderItems.value = true;
      order.value = JSON.parse(passedOrder);
      OrderService.getOrderItems(order.value.id).then(res => {
        loadingOrderItems.value = false;
        orderItems.value = res;
      });
    } else {
      // fetch both order & order items
      OrderService.getOrderById(route.params.id).then(res => {
        loading.value = false;
        order.value = res;
        orderItems.value = res.items;
      });
    }
  
    return {
      t,

      // icons
      location,
      
      // methods
      getOrderStatusColor,

      // variables
      loading, loadingOrderItems, order, orderItems,
    }
  },
}
